exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aufgaben-tsx": () => import("./../../../src/pages/aufgaben.tsx" /* webpackChunkName: "component---src-pages-aufgaben-tsx" */),
  "component---src-pages-boards-tsx": () => import("./../../../src/pages/boards.tsx" /* webpackChunkName: "component---src-pages-boards-tsx" */),
  "component---src-pages-impress-tsx": () => import("./../../../src/pages/impress.tsx" /* webpackChunkName: "component---src-pages-impress-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-projects-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/projects/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-projects-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-urgent-tsx": () => import("./../../../src/pages/urgent.tsx" /* webpackChunkName: "component---src-pages-urgent-tsx" */)
}

